<template lang="pug">
  .vue-pouvoir-lavoisier
    copie-pouvoir-en-cours(v-if="solved" names="lavoisier")
    div(v-else)
      img.w-100.p-absolute.bg(src="@/assets/pouvoirs/lavoisier/fond.jpg" srcset="@/assets/pouvoirs/lavoisier/fond@2x.jpg 2x")
      menu-button
      .text-block.titre {{$t('pouvoirs.lavoisier.titre')}}
      .text-block.element-etat
        | {{$t(`pouvoirs.lavoisier.elements.${sequence[sequenceIndex].el}`)}}
        | &nbsp;:&nbsp;
        | {{$t(`pouvoirs.lavoisier.etat.${sequence[sequenceIndex].state}`)}}
      .buttons
        button.plus.red(@click="add('R')") +
        button.minus.red(@click="remove('R')") -
        button.plus.blue(@click="add('B')") +
        button.minus.blue(@click="remove('B')") -
        button.plus.yellow(@click="add('Y')") +
        button.minus.yellow(@click="remove('Y')") -
        button.plus.purple(@click="add('P')") +
        button.minus.purple(@click="remove('P')") -
        button.plus.orange(@click="add('O')") +
        button.minus.orange(@click="remove('O')") -
        button.plus.green(@click="add('G')") +
        button.minus.green(@click="remove('G')") -

      .grid
        .grid-item(v-for="(item, idx) in elements" :id="'TT' + item.id" :key="item.id" :class="`grid-item-${item.el}`" :style="getGridItemStyle(idx)")

      button.validate(@click="validate") Valider
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'
import CopiePouvoirEnCours from '@/components/CopiePouvoirEnCours.vue'

export default {
  name: 'VuePouvoirLavoisier',

  components: {
    MenuButton, CopiePouvoirEnCours
  },

  computed: {
    currentState() {
      return this.sequence[this.sequenceIndex].state
    }
  },

  data () {
    return {
      id_el_seq: 9,
      elements: 'YORRPBBBG'.split('').map((el, id) => ({ el, id })),
      solved: false,
      sequence: [
        { el: 'chocolat', state: 'solide', solution: 'B=3,G=3,P=2,R=4' },
        { el: 'soda', state: 'liquide', solution: 'B=2,G=1,P=3,R=3,Y=3', initial: 'RGOPYYYYB' }
      ],
      sequenceIndex: 0
    }
  },

  methods: {
    getGridItemStyle(p) {
      if (this.currentState === 'solide') {
        const y = Math.floor(p / 4)
        const x = p % 4
        return {
          left: `${x * 120}px`,
          top: `${360 - y * 120}px`
        }
      } else { // liquid
        if (p <= 3) {
          return {
            left: `${p * 120}px`,
            top: '360px'
          }
        } else if (p <= 6) {
          return {
            left: `${60 + (p - 4) * 120}px`,
            top: '260px'
          }
        } else if (p <= 10) {
          return {
            left: `${(p - 7) * 120}px`,
            top: '160px'
          }
        } else {
          return {
            left: `${60 + (p - 11) * 120}px`,
            top: '60px'
          }
        }
      }
    },

    add(el) {
      this.$sound('add')
      if (this.currentState === 'solide') {
        if (this.elements.length < 16) {
          this.elements.push({ el, id: this.id_el_seq++ })
        }
      } else { // liquid
        if (this.elements.length < 14) {
          this.elements.push({ el, id: this.id_el_seq++ })
        }
      }
    },

    remove(el) {
      const p = this.elements.map(el => el.el).lastIndexOf(el)
      if (p !== -1) {
        this.$sound('remove')
        this.elements.splice(p, 1)
      } else {
        this.$sound('forbidden')
      }
    },

    validate() {
      const values = { B: 0, G: 0, O: 0, P: 0, R: 0, Y: 0 }
      this.elements.forEach(item => {
        values[item.el]++
      })
      const key = Object.entries(values)
        .filter(e => e[1] > 0)
        .map(e => `${e[0]}=${e[1]}`)
        .join(',')
      if (key === this.sequence[this.sequenceIndex].solution) {
        this.$sound('ok')
        if (this.sequenceIndex < (this.sequence.length - 1)) {
          this.sequenceIndex++
          this.elements = this.sequence[this.sequenceIndex].initial.split('').map((el, id) => ({ el, id }))
        } else {
          this.solved = true
        }
      } else {
        this.$sound('error')
      }
    }
  }
}
</script>

<style lang="less">
.vue-pouvoir-lavoisier {
  #menu-button {
    right: 60px;
  }

  .titre {
    position: absolute;
    left: 360px;
    top: 27px;
    width: 1220px;
    height: 132px;
  }

  .element-etat {
    position: absolute;
    left: 620px;
    top: 250px;
    width: 664px;
    height: 85px;
  }

  button.validate {
    position: absolute;
    padding: 5px 40px;
    left: 1520px;
    top: 996px;
  }

  .buttons {
    button {
      position: absolute;
      padding: 0px 30px;
      font-size: 50px;
      line-height: 50px;

      &:active {
        background: yellow;
      }

      &.red, &.blue, &.yellow { left: 227px; }
      &.red.plus, &.purple.plus { top: 241px; }
      &.red.minus, &.purple.minus { top: 342px; }
      &.blue.plus, &.orange.plus { top: 498px; }
      &.blue.minus, &.orange.minus { top: 599px; }
      &.yellow.plus, &.green.plus { top: 752px; }
      &.yellow.minus, &.green.minus { top: 853px; }
      &.purple, &.orange, &.green { left: 1573px; }
    }
  }

  .grid {
    position: absolute;
    top: 390px;
    left: 707px;
    width: 480px;
    height: 480px;
    background: white;
    border: 5px solid black;

    .grid-item {
      position: absolute;
      box-sizing: border-box;
      width: 120px;
      height: 120px;
      border: 5px solid black;
      border-radius: 60px;
      transition: top 250ms ease, left 250ms ease;

      &.grid-item-B {
        background: #4C9EF9;
      }
      &.grid-item-R {
        background: #FF006A;
      }
      &.grid-item-Y {
        background: #FFEF00;
      }
      &.grid-item-P {
        background: #9F41EF;
      }
      &.grid-item-O {
        background: #FF8600;
      }
      &.grid-item-G {
        background: #4DFFA6;
      }
    }
  }
}
</style>
